import React, { Component } from 'react';
import axios from 'axios';

export default class News extends Component {
  state = {
    news: null,
    offset: 0,
    count: 0,
  }

  request = async () => {
    const { limit } = this.props;
    this.setState({ fetchedData: null });
    const { data:news } = await axios.get('/crud/news', {
      params: {
        limit,
        offset: this.state.offset * limit,
      },
    });
    this.setState({ news, count:news.length });
  }

  componentDidMount = () => {
    this.request();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.offset !== prevState.offset) {
      this.request();
    }
  }

  reload = (offset) => {
    this.setState({ offset });
  }

  render() {
    const { news, offset, count } = this.state;
    const { limit } = this.props;
    if (!news) return <div>Loading</div>;
    return this.props.children(news, offset, limit, count, this.reload);
  }
}

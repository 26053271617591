import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../scss/navbar.scss';

export default class NavBar extends Component {
  state = {
    menuShow: false,
    searchShow: false
  }
  render() {
    const linksList = [
      {
        to: '/',
        caption: 'Главная',
      },
      {
        to: '/tourneys',
        caption: 'Турниры',
      },
      {
        to: '/pastcups',
        caption: 'Прошедшие турниры',
      },
      // {
      //   to: '/news',
      //   caption: 'Новости',
      // },
      // {
      //   to: '/notes',
      //   caption: 'Объявления',
      // },
      // {
      //   to: '/docs',
      //   caption: 'Документы',
      // }
    ]
    return (
      <div className='main-nav__wrapper'>
      <div className='main-nav__shadow'></div>
      <section className='main-nav'>
      <button className='main-nav__menu-btn' onClick={() => this.setState((prevState) => ({menuShow: !prevState.menuShow}))}>
        <span className='main-nav__btn-icon'></span>
      </button>
      <ul className={this.state.menuShow ? 'main-nav__list' : 'main-nav__list  main-nav__list--hidden'}>
        {linksList.map((link, index) => (
          <li key={index} className='main-nav__item'><Link to={link.to}>{link.caption}</Link></li>
        ))}
      </ul>
      <div className='main-nav__search'>
        <input className={this.state.searchShow ? 'main-nav__search-input  main-nav__search-input--show' : 'main-nav__search-input'} placeholder='Поиск игрока, команды или турнира'/>
        <button className={this.state.searchShow ? 'main-nav__search-btn main-nav__search-btn--dark' : 'main-nav__search-btn'} onClick={() => this.setState(prevState => ({searchShow: !prevState.searchShow}))}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"/>
            <path d="M0 0h24v24H0z" fill="none"/>
          </svg>
        </button>
      </div>
      </section>
      </div>
    )
  }
}

import React from 'react';

const ChessCell = ({ league, teamOne, teamTwo }) => {
  if (teamOne === teamTwo) return <td className="chess__blank" />;
  
  const matches = league.matches.filter(match => (
    (match.teamOne === teamOne && match.teamTwo === teamTwo) ||
    (match.teamTwo === teamOne && match.teamOne === teamTwo)
  ));
  return <td>
      {matches.map(m => <p>{m.score || "0:0"}</p>)}
  </td>

  // if (teamOne === teamTwo) return <td className="chess__blank" />;
  // return (
  //   <td>
  //     {matches.map((match) => {
  //       if (!match.played) return <p key={match._id}>—</p>;
  //       return (<p key={match._id}>{match.teamOne === teamOne ? match.score : match.score.split(':').reverse().join(':')}</p>);
  //     })}
  //   </td>
  // )
}

export default ChessCell;

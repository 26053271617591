const opts = { day: '2-digit', month: '2-digit', year: 'numeric' };
// FIXME this shit work corectly on other locales?
export function mdate(str) {
	let a = str.split('.');
	return new Date(a[2], a[1], a[0]);
}

export function toLDate(date) {
	return new Date(date).toLocaleDateString('ru', opts);
}

export function toLTime(date){
	return new Date(date).toLocaleString("ru", {
		// year: "numeric",
		// month: '2-digit',
		// day: '2-digit',
		hour: "2-digit",
		minute: "2-digit"
	})
}
import React, { Component } from 'react';
import axios from 'axios';
import { getEventName } from '../../constants';

export default class MatchPage extends Component {
  state = {
    match: null,
    league: null,
    teamOne: null,
    teamTwo: null,
    players: null
  }

  componentDidMount = async () => {
    const { data: [ match ] } = 
      await axios.get(`/crud/match?_id=${this.props.match.params.id}&_populate=place`);
    const { data: [ league ] } = await axios.get(`/crud/league?_id=${match.league}&_populate=tourney`);
    const {data : teams} = await axios.get(`/crud/team?_id=,${match.teamOne},${match.teamTwo}&_populate=players`);
    const playersList = teams.map(v => v.players.map(p => p.person)).flat();
    const {data: players} = playersList.length ? 
      await axios.get(`/crud/person?_id=,${playersList.join(',')}`):
      ({data:[]});
    
    console.log(players);

    const teamOne = teams.find(t => t._id === match.teamOne),
      teamTwo = teams.find(t => t._id === match.teamTwo);
    teamOne.players.forEach(p => p.person = players.find(op => op._id === p.person));
    teamTwo.players.forEach(p => p.person = players.find(op => op._id === p.person));
    console.log(teamOne,teamTwo);
    // const teamOne = league.teams.find(t => t._id === match.teamOne);
    // const teamTwo = league.teams.find(t => t._id === match.teamTwo);
    this.setState({ match, league, teamOne, teamTwo,players });
  }

  render() {
    const { match, league, teamOne, teamTwo, players } = this.state;
    if (!match || !league) return <div>Loading...</div>;
    const playersSet = new Set(match.playersList.map(player => player._id));
    const bothTeamsPlayers = players;
    return (
      <section>
        <h2 className="news__header">{`${league.tourney.name}, ${league.name}`}</h2>
        <p>{`${teamOne.name} - ${teamTwo.name}`}</p>
        {/* {TODO fix stage display} */}
        <p>{`Стадия: ${match.stage ? 'Плейофф' : 'Групповая'}, ${match.tour}`}</p>
        <p>{`Дата матча: ${match.date ? new Date(match.date).toLocaleString('ru') : 'не назначена'}.`}</p>
        <p>{`Место проведения: ${match.place ? match.place.name : 'не назначено'}`}</p>
        <p>{`Статус матча: ${match.played ? 'сыгран' : 'не сыгран'}`}</p>
        <article>
          <h4>Состав игроков: {teamOne.name}</h4>
          <div>
            {teamOne.players.length?teamOne.players
              .map(({person}) => {
                return <p key={person._id}>{`${person.surname} ${person.name}`}</p>;
              }):"Игроки отсутствуют"
            }
          </div>
          <h4>Состав игроков: {teamTwo.name}</h4>
          <div>
            {teamTwo.players.length?teamTwo.players
              // .filter(p => playersSet.has(p.playerId._id))
              .map(({person}) => {
                return <p key={person._id}>{`${person.surname} ${person.name}`}</p>;
              }):"Игроки отсутствуют"
            }
          </div>
        </article>
        {match.played && (
          <div>
            <div>{`Результат - ${(() => {
              switch(match.winner) {
                  case 'teamOne': return `победитель ${teamOne.name}`;
                  case 'teamTwo': return `победитель ${teamTwo.name}`;
                  case 'draw': return 'Ничья';
                  default: return 'Неизвестно';
                }
              })()}`}
            </div>
            <article>
              <h4>События:</h4>
              {match.events.map((e, index) => {
                const { playerId } = bothTeamsPlayers.find(p => p.playerId._id === e.player);
                return (
                  <p key={index}>{`${e.time} - ${getEventName(e.eventType)} - ${playerId.surname} ${playerId.name}`}</p>
                );
              })}
            </article>
          </div>
        )}
      </section>
    )
  }
}

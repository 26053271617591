import React from 'react';
import '../../scss/cups/match-table.scss';
import ChessTable from './ChessTable.jsx';

const ChessSection = ({ league }) => {
  if (!league) return <div>Loading</div>;
  // console.log(league.teams);
  const reformedData = league.teams.map(i => ({...i,_id:i.id,...i.stats}));
  let groups = [];
  reformedData.forEach(i => {
    // groups[i.name].teams.push(i);
    if (!groups.some(j => j.name === i.group)) {
      groups.push({name: i.group, teams: [i]});
    } else {
      groups[groups.findIndex(j => j.name === i.group)].teams.push(i);
    }
  });
  return (
    <section>
      {groups.map(group => (
        <ChessTable key={group.name} league={league} teams={group.teams} withGroups={groups.length > 1} />
      ))}
    </section>
  );
}

export default ChessSection;

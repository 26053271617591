import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiLink } from "../constants";
import "../scss/ads.scss";
import logo from "../assets/img/logo2.png";

export default class AdsBar extends Component {
  state = {
    ad: null,
  };

  async reload() {
    let q = this.props.ids
      ? `?attachId=,${this.props.ids.join(",")}`
      : "?attachId=null";
    const { data: ad } = await axios.get(`/crud/advert` + q);
    // const ad = [];
    this.setState({ ad: ad[Math.floor(Math.random() * ad.length)] });
  }

  componentDidMount = this.reload;

  render() {
    const { ad } = this.state;
    if (!ad) return null;
    return (
      <section className="ads">
        <div className="ads__placeholder">
          {ad && (
            <a href={ad.link}>
              <img
                src={`${apiLink}/${ad.site_img}`}
                alt="Место для рекламы"
                onError={() => this.reload()}
              />
            </a>
          )}
        </div>
      </section>
    );
  }
}

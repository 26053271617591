import React from 'react';
import { Link } from 'react-router-dom';
import { getStageName } from '../../constants';

const opts = { day:'2-digit', month:'2-digit', year:'numeric' };

const DetailCupItem = ({id, tourney, name, beginDate, endDate, teamsCount, status,region,ageMin,ageMax,yellowCards,transferBegin,transferEnd}) => (
    <>
    <Link className='cups__item' to={`/cups/${id}`}>
      
      <div className='cups__item-section'>
      <div className='cups__item-info'>
        <p className='cups__item-secondary'>Даты проведения</p>
        <span>{`${new Date(beginDate).toLocaleString('ru', opts)}—${new Date(endDate).toLocaleString('ru', opts)}`}
        </span>
      </div>
      <div className='cups__item-info'>
        <p className='cups__item-secondary'>Количество команд</p>
        <span className=''>{teamsCount}</span>
      </div>
      <div className='cups__item-info'>
        <p className='cups__item-secondary'>Схема турнира</p>
        <span>{getStageName(status)}</span>
      </div>
      <div className='cups__item-info'>
        <p className='cups__item-secondary'>Регион</p>
        <span className=''>{region}</span>
      </div>
      <div className='cups__item-info'>
        <p className='cups__item-secondary'>Трансферный период</p>
        <span className=''>{new Date(transferBegin).toLocaleDateString()} - {new Date(transferEnd).toLocaleDateString()}</span>
      </div>
      <div className='cups__item-info'>
        <p className='cups__item-secondary'>Возраст игроков</p>
        <span className=''>{ageMin} - {ageMax}</span>
      </div>
      <div className='cups__item-info'>
        <p className='cups__item-secondary'>Количество жёлтых карточек</p>
        <span className=''>{yellowCards}</span>
      </div>
      </div>
    </Link>
    </>
  )
  
export default DetailCupItem;
import React from 'react';
import { Link } from 'react-router-dom';
import { apiLink } from '../../constants';
import teamPic from './../../assets/img/team_logo.png';
import './../../scss/cups/teams-section.scss';

const TeamsSection = ({ teams, leagueId }) => {
  if (!teams) return <div>Loading</div>;
  return (
    <section>
      {teams.map(team => (
        <Link to={`/team/${leagueId}/${team._id}`} key={team._id} className='teams-list__item'>
          {team.logo ? <img src={apiLink + '/' + team.logo} alt={team.name} className='teams-list__logo'/> : null}
          <p className='teams-list__team-name'>{team.name}</p>
        </Link>
      ))}
    </section>
  )
}

export default TeamsSection;

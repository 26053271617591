import React from "react";
import { Route, Link } from "react-router-dom";
import "../scss/breadscrumb.scss";
import AdsBar from "./AdsBar";

const LinksBar = ({ links }) => {
  const sp = (links[2] || { to: "" }).to.split("/");
  // console.log(sp, links[2].to);
  const tname = (links[2] || { name: "" }).name;
  const last = sp[sp.length - 1];
  const ids = last ? [last] : [];
  
  return (
    <>
      <div style={{ textAlign: "center" }}>
        {tname && (
          <span style={{ fontWeight: 600, fontSize: "20px" }}>{tname}</span>
        )}
      </div>
      <AdsBar ids={ids} />
      <div className="breadscrumb">
        {links.map((i, index) => (
          <Route
            key={index}
            path={i.to}
            exact={true}
            children={({ match }) => (
              <span>
                {match && (
                  <span className="breadscrumb__current">{i.name}</span>
                )}
                {!match && (
                  <Link to={i.to} className="breadscrumb__link">
                    {i.name}
                  </Link>
                )}
                {index < links.length - 1 && (
                  <span className="breadscrumb__current  breadscrumb__current--margin">
                    /
                  </span>
                )}
              </span>
            )}
          />
        ))}
      </div>
    </>
  );
};

export default LinksBar;

import React, { Component } from 'react';
import axios from 'axios';
import { notesLimit as limit } from '../../constants';
import LinksBar from '../LinksBar.jsx';
import Pagination from '../Pagination.jsx';

export default class NotesList extends Component {
  state = {
    notes: null,
    offset: 0,
    count: 0,
  };

  request = async () => {
    this.setState({ notes: null });
    const { data: announces } = await axios.get('/crud/announce', {
      params: {
        offset: this.state.offset*limit,
        limit,
      },
    });
    this.setState({ notes: announces, count:announces.length });
  }

  componentDidMount = () => {
    this.request();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.offset !== this.state.offset) {
      this.request();
    }
  }

  render() {
    const { notes, offset, count } = this.state;
    if (!notes) return <div>Loading...</div>;
    return (
      <section>
        <LinksBar links={[
          {
            to:'/',
            name:'Главная'
          },
          {
            to:'/notes',
            name:'Объявления'
          }
        ]}/>
        {notes.map(note => (
          <div key={note._id} className="notes-list__item">
            <p className="notes-list__date">
              {note.date ? new Date(note.date).toLocaleString('ru') : ''}
            </p>
            <p className="notes-list__content">{note.content}</p>
          </div>
        ))}
        <Pagination
          offset={offset}
          limit={limit}
          count={count}
          reload={offset => this.setState({ offset })}
        />
      </section>
    )
  }
}

                                              //eslint-disable-next-line
import React, { Component } from 'react';

export default class TableControls extends Component {
    state = {
      appliedSort: null,
      ascendingSort: -1,
      selectedTeam: ''
    }

    changeSort = (field) => {
      if (this.state.appliedSort !== field) {
        this.setState({appliedSort: field, ascendingSort: 1});
      } else { this.setState(prevState => ({ascendingSort: prevState.ascendingSort*(-1)}))}
    }

    getClassName = (field) => {
      if (field === this.state.appliedSort) {
        if (this.state.ascendingSort === 1) {
          return 'table__cell--active asc';
        } else {
          return 'table__cell--active desc';       //eslint-disable-next-line
        };                                         //eslint-disable-next-line
      } else {return 'table__cell--inactive'};
    }

    applyFilter = (e) => {
      this.setState({selectedTeam: e.target.value});
    }

    render() {
      let reformedList;
      if (this.state.selectedTeam) {
        reformedList = this.props.tableData.filter(i => i.team.name === this.state.selectedTeam);
      } else {
        reformedList = [...this.props.tableData];
      };
      if (this.state.appliedSort) reformedList.sort((a,b) => {
        if (a[this.state.appliedSort] > b[this.state.appliedSort]) {
          return this.state.ascendingSort
        } else {
          return this.state.ascendingSort*(-1);
        }
      });
      return this.props.children(this.changeSort, this.getClassName, reformedList, this.applyFilter);
    }
}

import React, { Component } from 'react';
import LinksBar from '../LinksBar.jsx';
import axios from 'axios';
import { apiLink } from '../../constants';

export default class SingleNew extends Component {
  state = {
    news: null,
  };

  componentDidMount = async () => {
    const { data: { news } } = await axios.get(`/new/${this.props.match.params.id}`);
    this.setState({ news });
  }

  render() {
    const { news } = this.state;
    if (!news) return <div>Loading</div>;
    return(
      <React.Fragment>
        <LinksBar
          links={[
            {
              to:'/',
              name:'Главная'
            },
            {
              to:'/news',
              name:'Новости'
            },
            {
              to:`/news/${news._id}`,
              name: news.caption.slice(0,70)
            }
          ]}
        />
        <div className='news-page__item'>
          <div className='news-page__img-wrapper'>
            <div style={{backgroundImage: `url(${apiLink}/${news.img})`}} className='news-page__img' alt='Новость'/>
          </div>
          <div className='news-page__content'>
            <h2 className='news-page__header'>{news.caption}</h2>
            <div className='news-page__text'>
              {news.content.split('\n').map((i, index) => <p key={index}>{i}</p>)}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

import React from 'react';
import LinksBar from '../LinksBar.jsx';
import Pagination from '../Pagination.jsx';
import { Link } from 'react-router-dom';
import { apiLink } from '../../constants';
import '../../scss/news-page.scss';

const NewsPageRender = ({ list, offset, limit, count, reload }) => {
  return (
    <section className='news-page'>
    <LinksBar
      links={[
        {
          to:'/',
          name:'Главная'
        },
        {
          to:'/news',
          name:'Новости'
        }
      ]}
    />
      {list.map(i => (
        <Link key={i._id} to={`/news/${i._id}`}>
        <div className='news-page__item'>
          <div className='news-page__img-wrapper'>
            <div style={{backgroundImage: `url(${apiLink}/${i.img})`}} className='news-page__img' alt='Новость'/>
          </div>
          <div className='news-page__content'>
            <h2 className='news-page__header'>{i.caption}</h2>
            <p className='news-page__text'>{i.content.slice(0,300)}</p>
          </div>
        </div>
        </Link>
      ))}
      <Pagination
        offset={offset}
        count={count}
        limit={limit}
        reload={reload}
      />
    </section>
  )
}

export default NewsPageRender;

import React from "react";
import { Link } from "react-router-dom";
import { apiLink } from "../../constants";
import "../../scss/matches.scss";
import { mdate, toLDate, toLTime } from "../../utils";

function group(matches, gfn) {
  let ge = gfn,
    ve = gfn,
    sort = (a, b) => (a < b) * -1 + (a > b) * 1,
    filter = () => true;

  if (typeof gfn != "function") {
    ge = gfn.group;
    ve = gfn.view;
    sort = gfn.sort || sort;
    filter = gfn.filter || filter;
  }
  // filter matches and get calc group_key, view_key
  let mm = matches
    .filter(filter)
    .map((it) => ({ ...it, _group_key: ge(it), _view_key: ve(it) }));
  //create empty array to all groups
  let groups = new Set(mm.map((it) => it._group_key));
  let G = [...groups].reduce((c, v) => ({ [v]: [], ...c }), {});

  // accumulates groups in format {group_key:[matches]}
  mm.forEach((v) => G[v._group_key].push(v));

  //reformat data to [{name:view_key,items:[matches]}]
  let sg = [...groups].sort(sort);
  let ans = sg.map((it) => ({ name: G[it][0]._view_key, items: G[it] }));
  return ans;
}

//array comparator
function acmp(a, b) {
  let al = a.length,
    bl = b.length;
  let min = Math.min(al, bl);
  for (let i = 0; i < min; ++i) {
    if (a[i] < b[i]) return -1;
    else if (a[i] > b[i]) return 1;
  }
  return (min == al) * -1 && (min == bl) * 1;
}

let objectFromKeys = function(keys, fn) {
  keys = new Set(keys);
  let obj = {};
  console.log(keys);
  keys.forEach((k) => (obj[k] = fn(k)));
  return obj;
};

function agroup(matches, gfn) {
  let ge = gfn,
    ve = gfn,
    filter = () => true;
  if (typeof gfn != "function") {
    ge = gfn.group;
    ve = gfn.view;
    filter = gfn.filter || filter;
  }

  let mm = matches
    .filter(filter)
    .map((it) => [...ge(it), ve(it), it])
    .sort(acmp);
  let G = objectFromKeys(
    mm.map((v) => v[v.length - 2]),
    () => []
  );
  mm.forEach((a) => {
    let l = a.length,
      v = a[l - 2],
      i = a[l - 1];
    G[v].push(i);
  });
  return Object.entries(G).map((it) => ({ name: it[0], items: it[1] }));
  // return G;
}

const groupFunctions = {
  "Дате проведения": {
    //date in format like dd.mm.yyyy
    filter: (v) => new Date(v.date) > new Date(1980) && v.place,
    //group return value must be placed in Set
    group: (it) => [
      ...toLDate(it.date)
        .split(".")
        .reverse()
        .map((it) => -+it),
      (it.place || {}).name,
      toLTime(it.date),
    ],
    view: (v) => toLDate(v.date),
  },
  Туру: (v) => `${+v.round + 1} круг ${v.tour}`,
  // "Стадиону": v => (v.place || {}).name || "Стадион не указан",
};

class MatchesSchedule extends React.Component {
  state = {
    gfn: groupFunctions["Дате проведения"],
  };
  render() {
    let { matches, league, hideGroups } = this.props;
    // filter undefined teams
    matches = (matches || []).filter((it) => it.teamOne && it.teamTwo);
    if (!matches) return <div>Loading...</div>;
    let data = agroup(matches, this.state.gfn);
    const fnna = [];
    for (let key in groupFunctions) fnna.push(key);

    return (
      <section className="matches">
        {!hideGroups && (
          <>
            <span>Группировать по: </span>
            <select
              defaultValue={fnna[0]}
              onChange={(e) =>
                this.setState({ gfn: groupFunctions[e.target.value] })
              }
            >
              {fnna.map((v) => (
                <option key={v} value={v}>
                  {v}
                </option>
              ))}
            </select>
          </>
        )}
        <table className="matches__table">
          <tbody>
            {data.map((i) => (
              <MatchItem
                key={i.name}
                name={i.name}
                matches={i.items}
                league={league}
              />
            ))}
          </tbody>
        </table>
      </section>
    );
  }
}

const MatchItem = ({ name, matches, league }) => (
  <React.Fragment>
    <tr>
      <td className="matches__date" colSpan="3">
        {name}
      </td>
    </tr>
    {matches.map((i, id, items) => {
      const stadChanges =
        id > 0
          ? (i.place || {}).name != (items[id - 1].place || {}).name
          : false;
      const teamOne = (i.teamOne || {}).name || "Команда не назначена";
      const teamTwo = (i.teamTwo || {}).name || "Команда не назначена";
      const tol = (i.teamOne || {}).logo;
      const ttl = (i.teamTwo || {}).logo;

      return [
        stadChanges ? <hr /> : null,
        <tr key={i._id} className="matches__row">
          <td className="match__date">
            <span className="matches__item-secondary">{toLDate(i.date)}</span>
          </td>
          <td className="match__time">
            <span className="matches__item-secondary">{toLTime(i.date)}</span>
          </td>
          <td className="match__teams">
            <span className="team__name">
              <Link
                to={`/team/${i.league}/${(i.teamOne || {})._id}`}
                className="matches__link"
              >
                {teamOne}{" "}
                {tol ? (
                  <img
                    src={apiLink + "/" + tol}
                    alt=""
                    width="16"
                    height="16"
                  />
                ) : null}
              </Link>
            </span>
            <span className="match__score">
              <Link to={`/match/${i._id}`} className="matches__link">
                {i.score || "—"}
              </Link>
            </span>
            <span className="team__name">
              <Link
                to={`/team/${i.league}/${(i.teamTwo || {})._id}`}
                className="matches__link"
              >
                {ttl ? (
                  <img
                    src={apiLink + "/" + ttl}
                    alt=""
                    width="16"
                    height="16"
                  />
                ) : null}
                {teamTwo}
              </Link>
            </span>
          </td>
          <td className="match__league-name">
            <span className="matches__item-secondary">
              {(i.league || { name: "Лига не указана" }).name}
            </span>
          </td>
          <td className="match__staduim-name">
            <span className="matches__item-secondary">
              {(i.place || { name: "Стадион не указан" }).name}
            </span>
          </td>
        </tr>,
      ];
    })}
  </React.Fragment>
);

export default MatchesSchedule;

import React from "react";
// import withTableControls from './withTableControl.jsx';
import TableControls from "./TableControls.jsx";
import PlayerTableRenderer from "./PlayerTableRenderer.jsx";
import { TeamName } from "../TeamName.jsx";

const PlayersList = ({ league, players }) => {
  if (!league) return <div>Loading</div>;
  console.log(players);

  const teams = league.teams;
  console.log(teams);

  return (
    <TableControls tableData={players}>
      {(changeSort, getClassName, tableData, applyFilter) => (
        <div>
          <label className="players__select-header">
            Выберите команду:
            <select
              defaultValue=""
              onChange={(e) => applyFilter(e)}
              className="players__select"
            >
              <option value="">Все</option>
              {teams.map((i, index) => (
                <option key={index} value={i.name}>
                  {i.name}
                </option>
              ))}
            </select>
          </label>
          <PlayerTableRenderer
            changeSort={changeSort}
            getClassName={getClassName}
            list={tableData}
            withTeams
          />
        </div>
      )}
    </TableControls>
  );
};

export default PlayersList;
// const EnhancedTable = withTableControls(PlayerTableRenderer, this.state.list, true, this.state.teamsList);
// return <EnhancedTable />

import React from "react";
import { TeamName } from "../TeamName";

const PlayerTableRenderer = ({ changeSort, getClassName, list, withTeams }) => (
  <table className="table" v={(_) => console.log(list)}>
    <thead className="table__heading">
      <tr>
        <th
          onClick={() => changeSort("surname")}
          className={getClassName("surname")}
        >
          Игрок
        </th>
        {withTeams && (
          <th
            onClick={() => changeSort("team")}
            className={getClassName("team")}
          >
            Команда
          </th>
        )}
        <th
          onClick={() => changeSort("goals")}
          className={getClassName("goals")}
        >
          Голы
        </th>
        <th
          onClick={() => changeSort("matches")}
          className={getClassName("matches")}
        >
          Игры
        </th>
        <th
          onClick={() => changeSort("yellow")}
          className={`players__table-cell--secondary ${getClassName("yellow")}`}
        >
          ЖК
        </th>
        <th
          onClick={() => changeSort("redCards")}
          className={`players__table-cell--secondary ${getClassName(
            "redCards"
          )}`}
        >
          КК
        </th>
      </tr>
    </thead>
    <tbody>
      {list.map((i) => (
        <tr key={i._id} className="table__item">
          <td>{`${i.surname} ${i.name}`}</td>
          {withTeams && (
            <td>
              <TeamName team={i.team} />{" "}
            </td>
          )}
          <td>{i.goals}</td>
          <td>{i.matches}</td>
          <td className="players__table-cell--secondary">{i.yellowCards}</td>
          <td className="players__table-cell--secondary">{i.redCards}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export default PlayerTableRenderer;

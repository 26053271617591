import React from 'react';
import { Link } from 'react-router-dom';
import { apiLink } from '../../constants';
import '../../scss/news-section.scss';

const NewsSectionRender = ({ list }) => {
  const featured = list[0];
  const news = list.slice(1);
  return (
    <div className='news__wrapper'>
      <Link to="/news"><h2 className='news__header'>Новости</h2></Link>
      <section className='news'>
        {featured && (
          <div className='news__left'>
            <Link to={`/news/${featured._id}`}>
              <div className='news__featured-img' style={{backgroundImage: `url(${apiLink}/${featured.img})`}}/>
              <span className='news__featured-link'>Новость дня</span>
              <h3 className='news__featured-header'>{featured.caption}</h3>
            </Link>
          </div>
        )}
        <div className='news__right'>
          {news.map(i => (
            <Link to={`/news/${i._id}`} key={i._id} className='news__item'>
              <div
                className='news__item-img'
                style={{backgroundImage: `url(${apiLink}/${i.img})`}}
                alt='Новость'
                width="150"
                height="100"
              />
              <div className='news__item-content'>
                <h3 className='news__item-header'>{i.caption}</h3>
                <p className='news__item-text'>{i.content.slice(0,300)}</p>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  )
}

export default NewsSectionRender;

import React from "react";
import { apiLink } from "../constants";

export const TeamName = ({ team }) => (
  <>
    {team.logo ? (
      <img
        src={apiLink + "/" + team.logo}
        alt=""
        width="16"
        height="16"
        style={{ marginRight: "8px" }}
      />
    ) : null}
    {team.name}
  </>
);

import React from "react";
import "../../scss/info-section.scss";
import { toLDate } from "../../utils";

function InformationSection({
  region,
  name,
  yellowCardsToDisqual,
  ageAllowedMin,
  ageAllowedMax,
  minPlayersInMatch,
  maxPlayersInMatch,
  playersMin,
  playersMax,
  drawDateTime,
  transferBegin,
  transferEnd,
  beginDate,
  endDate,
  teamCount,
}) {
  return (
    <>
      <div className="info-block">
        <span className="info-header">Регион</span>
        <span className="info-value">{region.name}</span>
      </div>
      <div className="info-block">
        <span className="info-header">Название турнира</span>
        <span className="info-value">{name}</span>
      </div>
      <div className="info-block">
        <span className="info-header">Количество комманд</span>
        <span className="info-value">{teamCount}</span>
      </div>
      <div className="info-block">
        <span className="info-header">Количество жёлтых карточек</span>
        <span className="info-value">{yellowCardsToDisqual}</span>
      </div>
      <div className="info-block">
        <span className="info-header">Возраст игроков</span>
        <span className="info-value">
          {ageAllowedMin || 1900} - {ageAllowedMax || 2100}
        </span>
      </div>
      <div className="info-block">
        <span className="info-header">Количество игроков в заявке</span>
        <span className="info-value">
          {playersMin} - {playersMax}
        </span>
      </div>
      <div className="info-block">
        <span className="info-header">количество игроков в матче</span>
        <span className="info-value">
          {minPlayersInMatch} - {maxPlayersInMatch}
        </span>
      </div>
      <div className="info-block">
        <span className="info-header">Дата и время жеребъёвки</span>
        <span className="info-value"></span>
      </div>
      <div className="info-block">
        <span className="info-header">Дата проведения</span>
        <span className="info-value">
          {toLDate(beginDate)} - {toLDate(endDate)}
        </span>
      </div>
      <div className="info-block">
        <span className="info-header">Трансферный период</span>
        <span className="info-value">
          {toLDate(transferBegin)} - {toLDate(transferEnd)}
        </span>
      </div>
    </>
  );
}

export default InformationSection;

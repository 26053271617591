import React from 'react';

import News from './News.jsx';
import NewsSectionRender from './News/NewsSectionRender.jsx';
import UpcomingMatches from './UpcomingMatches.jsx';
import NotesSection from './Notes/NotesSection.jsx';
import '../scss/main.scss';

const Main = () => (
  <React.Fragment>
    {/* <UpcomingMatches /> */}
    <div className='main__special'>
      <News limit={4}>
        {news => (<NewsSectionRender list = {news}/>)}
      </News>
      <NotesSection />
    </div>
    <div className="faq">
      <h3>Часто задаваемые вопросы</h3>
        <h4>Nisi sint minim ullamco velit qui ex ipsum nulla deserunt fugiat incididunt quis qui laboris.</h4>
        <p>Excepteur tempor non ullamco aliqua enim adipisicing cupidatat ex eu minim ipsum cupidatat. Ex consequat qui laboris id duis. Consequat labore anim in exercitation ea. Irure aute minim excepteur eiusmod et culpa est voluptate excepteur do tempor velit occaecat. Eiusmod et nisi laboris nostrud ullamco fugiat dolor quis aute fugiat adipisicing est ea.
        </p>
        <h4>Sunt est ad ut culpa do sit aute dolor.</h4>
        <p>
          Minim et pariatur eu duis commodo. Occaecat in id ut labore ea irure eu ipsum sunt cupidatat esse. Commodo occaecat labore eiusmod adipisicing pariatur sunt. Do sit ex reprehenderit fugiat enim culpa reprehenderit. Eiusmod ullamco incididunt eiusmod qui velit reprehenderit eiusmod amet. Qui quis aute non laborum.
        </p>
    </div>
  </React.Fragment>
)

export default Main;

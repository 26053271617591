import React from 'react';
import '../scss/pagination.scss';

const calcPages = (offset, limit, count) => {
 if (count / limit <= 5) return Array.from({ length: Math.ceil(count / limit) }, (i,index) => index);
 if (offset < 3) return [0, 1, 2, 3, 4];
 if (offset >= count / limit - 2) {
   const last = Math.ceil(count / limit);
   return [last - 5, last - 4, last - 3, last - 2, last - 1];
 }
 return [offset - 2, offset - 1, offset, offset + 1, offset + 2];
};

const Pagination = ({ limit, offset, count, reload }) => {
  if (limit >= count) return <div />;
  const pages = calcPages(offset, limit, count);
  return (
    <section className="pagination__container">
      <div className="pagination">
        <button disabled={offset <= 0} onClick={() => reload(offset-1)}>{'<'}</button>
        {pages.map(page => (
          <button key={page} disabled={offset===page} onClick={() => reload(page)}>
            {page+1}
          </button>
        ))}
        <button disabled={limit*(offset+1)>=count} onClick={() => reload(offset+1)}>{'>'}</button>
      </div>
    </section>
  )
}

export default Pagination;

import React from 'react';
import { Link } from 'react-router-dom';
import { getStageName } from '../../constants';

const opts = { day: '2-digit', month: '2-digit', year: 'numeric' };
const dateFormat = (date) => (typeof date == 'string' ? new Date(date) : date).toLocaleString('ru', opts)

const TourneyDetail = ({ _id, name, beginDate, endDate, maxTeams: teamsCount, status,
    region, ageAllowedMin: ageMin, ageAllowedMax: ageMax,
    yellowCardsToDisqual: yellowCards, transferBegin, transferEnd, leagueCount }) => (
        <>
            <Link className='cups__item' to={`/cups/${_id}`}>
                <div className='cups__item-section'>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Регион</p>
                        <span className=''>{region.name}</span>
                    </div>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Даты проведения</p>
                        <span>{dateFormat(beginDate)} — {dateFormat(endDate)}
                        </span>
                    </div>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Количество команд</p>
                        <span className=''>{teamsCount}</span>
                    </div>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Схема турнира</p>
                        <span>{getStageName(status)}</span>
                    </div>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Трансферный период</p>
                        <span className=''>{dateFormat(transferBegin)} - {dateFormat(transferEnd)}</span>
                    </div>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Возраст игроков</p>
                        <span className=''>{ageMin || 1900} - {ageMax || 2100}</span>
                    </div>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Количество жёлтых карточек</p>
                        <span className=''>{yellowCards}</span>
                    </div>
                    <div className='cups__item-info'>
                        <p className='cups__item-secondary'>Количество лиг</p>
                        <span className=''>{leagueCount || 0}</span>
                    </div>
                </div>
            </Link>
        </>
    )

export default TourneyDetail;
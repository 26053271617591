import React, { Component } from 'react';
import axios from 'axios';
import LinksBar from '../LinksBar.jsx';
import TableControls from '../Cups/TableControls.jsx';
import PlayerTableRenderer from '../Cups/PlayerTableRenderer.jsx';
import { apiLink } from '../../constants';
import { Route, Link } from 'react-router-dom';
import '../../scss/teamspage.scss';

export default class TeamsPage extends Component {
	state = {
		league: null,
		team: null,
		club: null,
		matches: null,
	};

	componentDidMount = async () => {
		const { leagueId, id } = this.props.match.params;
		// const { data: { league } } = await axios.get(`/leagues/league/${leagueId}?players=true`);
		const { data: [league] } = await axios.get(`/crud/league?_id=${leagueId}&_populate=players+tourney+teams.players.playerId`)
		const { data: [team] } = await axios.get(`/crud/team?_id=${id}&_populate=players`);
		console.log(team.players);
		const { data: [stats] } = await axios.get(`/stats/team?team=${id}&on=${leagueId}`);
		const { data: m1 } = await axios.get(`/crud/match?teamOne=${id}&_populate=teamOne+teamTwo`);
		const { data: m2 } = await axios.get(`/crud/match?teamTwo=${id}&_populate=teamOne+teamTwo`);
		const ps = team.players.map(ps => ps.person).join(',');
		const {data:players} = ps ? 
			await axios.get(`/crud/person?_id=,${ps}`):
			({data:[]});
		const {data:pstats} = await axios.get(`/stats/person?on_=${leagueId}`);
		team.players = team.players.map(v => ({
			...players.find(p => p._id == v.person),
			...pstats.find(p => p.person == v.person)
		}));
		
		const matches = [...m1, ...m2];
		console.log(matches);
		this.setState({ league, team, stats: stats || { wins: 0, losses: 0, draws: 0, groupScore: 0 }, matches });

		
		console.log(players);
		// this.setState({ club });
	}

	render() {
		const { leagueId, id } = this.props.match.params;
		const { league, team, club, stats, matches } = this.state;
		if (!league || !team) return <div>Loading...</div>;
		return (
			<section>
				<LinksBar links={[
					{
						to: '/',
						name: 'Главная',
					},
					{
						to: '/cups',
						name: 'Турниры',
					},
					{
						to: `/cups/${league._id}`,
						name: `${league.tourney.name}. ${league.name}`,
					},
					{
						to: `/team/${leagueId}/${id}`,
						name: `${team.name}`,
					}
				]} />
				<div className="teams__header">
					{team.logo ? <img src={`${apiLink}/${team.logo}`} width="200" height="200" alt="Логотип" /> : null}
					<h2>{team.name}</h2>
				</div>
				<div>{`Турнир: ${league.tourney.name}. ${league.name}`}</div>
				<div>
					<h5>Статистика команды:</h5>
					<p>{`${stats.wins} побед`}</p>
					<p>{`${stats.losses} поражений`}</p>
					<p>{`${stats.draws} ничей`}</p>
					<p>{`${stats.groupScore} очков`}</p>
				</div>
				<div>
					<h5>Матчи комманды</h5>
					{
						matches.map(m => <Match match={m}/>)
					}
				</div>
				{team.place !== 0 && <div>{`Место команды: ${team.place}${team.madeToPlayoff ? 'Место в плейоффе' : 'Место в группе'}`}</div>}
				<h5>Состав участников:</h5>
				<div>
					<TableControls tableData={team.players}>
						{(changeSort, getClassName, tableData, applyFilter) => (
							<PlayerTableRenderer changeSort={changeSort} getClassName={getClassName} list={tableData} />
						)}
					</TableControls>
				</div>
			</section>
		);
	}
}

function Match({ match }) {
	return <Link to={`/match/${match._id}`} key={match._id}>
		<div style={{boxShadow:'0 2px 4px grey',margin:'4px'}}>
			<span> {new Date(match.date).toLocaleString("ru", { hour: "2-digit", minute: "2-digit" })} </span>
			<span> {(match.teamOne || {name:"Команда не назначена"}).name}</span>
			<span> {(match.teamTwo || {name:"Команда не назначена"}).name}</span>
		</div>
	</Link>
}
import React from "react";
import { apiLink } from "../../constants";
import { TeamName } from "../TeamName";

const TableSectionItem = ({ changeSort, getClassName, list, withGroups }) => (
  <div>
    {withGroups && <h3>Группа {list[0].group}</h3>}
    <table className="table">
      <thead>
        <tr className="table__heading">
          <th
            onClick={() => changeSort("name")}
            className={getClassName("name")}
          >
            Команда
          </th>
          <th
            onClick={() => changeSort("games")}
            className={getClassName("games")}
          >
            И
          </th>
          <th
            onClick={() => changeSort("wins")}
            className={getClassName("wins")}
          >
            В
          </th>
          <th
            onClick={() => changeSort("draws")}
            className={getClassName("draws")}
          >
            Н
          </th>
          <th
            onClick={() => changeSort("losses")}
            className={getClassName("losses")}
          >
            П
          </th>
          <th
            onClick={() => changeSort("goals")}
            className={getClassName("goals")}
          >
            Г
          </th>
          <th
            onClick={() => changeSort("goalsDiff")}
            className={getClassName("goalsDiff")}
          >
            РГ
          </th>
          <th
            onClick={() => changeSort("groupScore")}
            className={getClassName("groupScore")}
          >
            О
          </th>
        </tr>
      </thead>
      <tbody>
        {list.map((i) => (
          <tr key={i._id} className="table__item">
            <td>
              <TeamName team={i} />
            </td>
            <td>{i.games}</td>
            <td>{i.wins}</td>
            <td>{i.draws}</td>
            <td>{i.losses}</td>
            <td>
              {i.goals} - {i.goalsReceived}
            </td>
            <td>{i.goals - i.goalsReceived}</td>
            <td>{i.groupScore}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default TableSectionItem;

import React from 'react'
import '../../scss/news-section.scss'
import {toLDate} from '../../utils'
function News({caption,img,content,createdAt}) {
    return <div>
        <h3>{caption}</h3>
        <img src={"http://football.bw2api.ru/" + img} alt="" class="news__image"/>
        <p>{content}</p>
        <span className="news__date">
        {toLDate(createdAt)}
        </span>
    </div>
}

function Anounce({content,createdAt}) {
    return <div>
        <p>{content}</p>
        <span class="announce__date">{toLDate(createdAt)}</span>
    </div>
}

function NewsSection({ news, announces }) {
    return <div className="news__block">
        <div className="news__column">
            <h3>Новости</h3>
            {news.map(it => <News {...it} />)}
        </div>
        <div className="anounce__column">
            <h3>Аннонсы</h3>
            {announces.map(it => <Anounce {...it} />)}
        </div>
    </div>
}

export default NewsSection;
import React from 'react';
import { Link } from 'react-router-dom';

const opts = { day:'2-digit', month:'2-digit', year:'numeric' };

const TourneyItem = ({ _id, tourney, name, beginDate, endDate, teamsCount, status,region,ageMin,ageMax,yellowCards,transferBegin,transferEnd}) => (
  <Link className='cups__item' to={`/tourney/${_id}`}>
    <h3>{`${name}.`}</h3>
  </Link>
)

export default TourneyItem;
import React, { Component } from "react";
import axios from "axios";
import MatchesSchedule from "./MatchesSchedule.jsx";
import LinksBar from "../LinksBar.jsx";
import CupItem from "./CupItem.jsx";
import DetailCupItem from "./DetailCupItem.jsx";
import TeamsSection from "./TeamsSection.jsx";
import MatchesPage from "./MatchesPage.jsx";
import TableSection from "./TableSection.jsx";
import ChessSection from "./ChessSection.jsx";
import PlayersList from "./PlayersList.jsx";
import "../../scss/cups/cups-page.scss";
import "../../scss/cups/players.scss";

import News from "./News";
import Announces from "./Announces";
import { Link } from "react-router-dom";
import NewsPage from "./News";
import InformationSection from "../Tourney/InformationSection.jsx";
import NewsSection from "../Tourney/NewsSection.jsx";
import Docs from "../Docs/Docs.jsx";
export default class CupsPage extends Component {
  state = {
    league: null,
    players: null,
    matches: null,
    news: [],
    annoounces: [],
    activeTab: 0,
  };

  componentDidMount = async () => {
    let lid = this.props.match.params.id;
    const {
      data: [league],
    } = await axios.get(
      `/crud/league?_id=${lid}&_populate=team_request+tourney+matches+stage`
    );

    const tids = league.team_request
      .filter((r) => r.status == "accepted")
      .map((v) => v.team);

    const { data: stats } =
      tids.length > 0
        ? await axios.get(`/stats/team?on_=${lid}&team=,${tids.join(",")}`)
        : { data: [] };

    const { data: matches } = await axios.get(
      `/crud/match?league=${lid}&_populate=place+teamOne+teamTwo`
    );
    const {
      data: [region],
    } = await axios.get(`/crud/region?_id=${league.tourney.region}`);
    league.tourney.region = region;
    const stm = {};
    stats.forEach((v) => (stm[v.team] = v));
    // console.log(stats);

    // console.log(league);
    league.teams = await Promise.all(
      league.team_request
        .filter((r) => r.status == "accepted")
        .map(async (v) => {
          const o = (
            await axios.get(`/crud/team?_id=${v.team}&_populate=players`)
          ).data[0];
          return {
            ...o,
            stats: stm[v.team] || {
              wins: 0,
              losses: 0,
              draws: 0,
              games: 0,
              goals: 0,
              goalsDiff: 0,
              goalsReceived: 0,
            },
          };
        })
    );

    let players = league.teams.reduce((list, team) => {
      return [
        ...list,
        ...team.players.map((player) => {
          return {
            ...player,
            team: team,
            //team: team.name TODO check this shit for error
            // surname: player.playerId.surname
          };
        }),
      ];
    }, []);
    console.log(league.matches);
    console.log(
      league.matches
        .filter((v) => v.teamOne != null && v.teamTwo != null)
        .map((v) =>
          v.teamOne < v.teamTwo
            ? `${v.teamOne}:${v.teamTwo}`
            : `${v.teamTwo}:${v.teamOne}`
        )
        .reduce((sum, v) => ({ ...sum, [v]: (sum[v] || 0) + 1 }), {})
    );

    const spm = {},
      ppm = {};

    if (players.length) {
      const { data: pstats } = await axios.get(
        `/stats/person?on_=${lid}&person=,${players
          .map((v) => v.person)
          .join(",")}&_limit=${players.length}`
      );
      pstats.forEach((v) => (spm[v.person] = v));
      const { data: p } = await axios.get(
        `/crud/person?_id=,${players.map((v) => v.person).join(",")}&_limit=${
          players.length
        }`
      );
      p.forEach((v) => (ppm[v._id] = v));
    }
    // console.log("SPM", spm);
    // console.log("PPM", ppm);
    players = players.map((v) => ({
      ...v,
      ...ppm[v.person],
      ...(spm[v.person] || {
        goals: 0,
        yellowCards: 0,
        redCards: 0,
        matches: 0,
      }),
    }));

    const { data: news } = await axios.get(
      `/crud/news?tourney=${league.tourney._id}`
    );
    const { data: annoounces } = await axios.get(
      `/crud/announce?tourney=${league.tourney._id}`
    );
    // console.log(players,spm,ppm);
    //await axios.get(`/leagues/league/${this.props.match.params.id}?players=true`);
    this.setState({ league, players, matches, news, annoounces });
  };

  changeTab = (e) => {
    this.setState({ activeTab: +e.target.dataset.index });
  };

  render() {
    const {
      league,
      players,
      activeTab,
      matches,
      news,
      annoounces,
    } = this.state;
    console.log(league);
    if (!league) return <div>Loading</div>;
    const tabs = [
      {
        title: "Календарь игр",
        component: <MatchesSchedule matches={matches} league={league} />,
      },
      // {
      // 	title: 'Матчи',
      // 	component: <MatchesPage league={league} />
      // },
      {
        title: "Команды",
        component: <TeamsSection teams={league.teams} leagueId={league._id} />,
      },
      {
        title: "Турнирная таблица",
        component: <TableSection teams={league.teams} />,
      },
      {
        title: "Шахматка",
        component: <ChessSection league={league} />,
      },
      {
        title: "Статистика игроков",
        component: <PlayersList league={league} players={players} />,
      },
      {
        title: "Новости",
        component: <NewsSection news={news} announces={annoounces} />,
      },
      // {
      // 	title: 'Объявления',
      // 	component: <Announces announces={annoounces} />
      // },
      {
        title: "Регламент",
        component: league ? (
          <InformationSection
            {...league}
            region={league.tourney.region}
            teamCount={league.teams.length}
          />
        ) : null,
      },
      {
        title: "Документы",
        component: <Docs attachId={league._id} />,
      },
      {
        title: (
          <a
            href={`https://football.bwadm.ru/tourney/${league.tourney._id}/league/${league._id}`}
            style={{ color: "#222" }}
          >
            Редактировать
          </a>
        ),
      },
    ];
    return (
      <section>
        <LinksBar
          links={[
            {
              to: "/",
              name: "Главная",
            },
            {
              to: "/tourneys",
              name: "Турниры",
            },
            {
              to: `/tourney/${league.tourney._id}`,
              name: `${league.tourney.name}`,
            },
            {
              to: `/cups/${league._id}`,
              name: `${league.name}`,
            },
          ]}
        />
        <DetailCupItem
          id={league._id}
          tourney={league.tourney}
          name={league.name}
          beginDate={league.beginDate}
          endDate={league.endDate}
          teamsCount={league.teams.length}
          status={league.status}
          region={league.tourney.region.name}
          ageMin={league.ageAllowedMin}
          ageMax={league.ageAllowedMax}
          yellowCards={league.yellowCardsToDisqual}
          transferBegin={league.transferBegin}
          transferEnd={league.transferEnd}
        />
        <ul className="cup-page__tab-list">
          {tabs.map((i, index) => (
            <li
              key={index}
              className={`cup-page__tab ${
                index === activeTab ? "cup-page__tab--active" : ""
              }`}
              onClick={this.changeTab}
              data-index={index}
            >
              {i.title}
            </li>
          ))}
        </ul>
        {activeTab !== null && tabs[activeTab] && tabs[activeTab].component}
      </section>
    );
  }
}

import React, { Component } from "react";
import axios from "axios";
import { notesLimit as limit, apiLink } from "../../constants";
import LinksBar from "../LinksBar.jsx";
import Pagination from "../Pagination.jsx";

export default class Docs extends Component {
  state = {
    docsList: null,
    offset: 0,
    count: 0,
  };
  asd;

  request = async () => {
    this.setState({ docsList: null });
    const { data: docs } = await axios.get(
      `/crud/document?attachId=${this.props.attachId}`
    );
    this.setState({ docsList: docs, count: docs.length });
  };

  componentDidMount = () => {
    this.request();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.offset !== this.state.offset) {
      this.request();
    }
  };

  render() {
    const { docsList, offset, count } = this.state;
    if (!docsList) return <div>Loading...</div>;
    return (
      <section>
        <LinksBar
          links={[
            {
              to: "/",
              name: "Главная",
            },
            {
              to: "/docs",
              name: "Документы",
            },
          ]}
        />
        {docsList.map((doc) => (
          <div key={doc._id} className="notes-list__item">
            <p className="notes-list__date">
              {doc.createdAt
                ? new Date(doc.createdAt).toLocaleString("ru")
                : ""}
            </p>
            <a href={`${apiLink}/${doc.file}`}>
              <span className="notes-list__content  notes-list__content--link">
                {doc.name}
              </span>
            </a>
          </div>
        ))}
        <Pagination
          offset={offset}
          limit={limit}
          count={count}
          reload={(offset) => this.setState({ offset })}
        />
      </section>
    );
  }
}

import React, { Component } from 'react';
import axios from 'axios';
import { leagueLimit as limit } from '../../constants';
import LinksBar from '../LinksBar.jsx';
import CupItem from './CupItem.jsx';
import Pagination from '../Pagination.jsx';
import '../../scss/cups/cups.scss';

export default class Cups extends Component {
  state = {
    leagues: null,
    offset: 0,
    count: 0,
  };

  request = async () => {
    this.setState({ leagues: null });
    // const { data: { leagues, count } } = await axios.get(`/leagues/all?type=${this.props.past ? 'finished' : 'ongoing'}`, {
    let { data: leagues } = await axios.get(`/crud/league`, {
      params: {
        status:"started",
        _offset: this.state.offset * limit,
        _limit:10,
        _populate:"team_request tourney" 
      },
    });

    leagues = await Promise.all(leagues.map(async l => {
      l.teams = await Promise.all(
        l.team_request.
          filter(t => t.status === "accepted").
          map(async v => (await axios.get(`/crud/team?_id=${v.team}`)).data[0])
      )
      return l;
    }));

    // const ans = await axios.get('/crud/league?_populate=tourney&status=Groups');
    // console.log(ans.data);
    // console.log(leagues);
    this.setState({ leagues, count:leagues.length });
  }

  componentDidMount = () => {
    this.request();
  }


  componentDidUpdate = async (prevProps, prevState) => {
    if (prevProps.past !== this.props.past || prevState.offset !== this.state.offset) {
      this.request();
    }
  }

  render() {
    const { leagues, offset, count } = this.state;
    if (!leagues) return <div>Loading</div>;
    this.links = [
      {
        to:'/',
        name:'Главная'
      }
    ];
    if (this.props.past) {
      this.links.push({
        to:'/pastcups',
        name:'Прошедшие турниры'
      });
    } else {
      this.links.push({
        to:'/cups',
        name:'Турниры'
      });
    }
    return (
      <section>
        <LinksBar
          links={this.links}
        />
        <h2 className='cups__heading'>Турниры</h2>
        <div>
          {leagues.length === 0 && <span>Турниров нет</span>}
          {leagues.map(i => <CupItem
            key={i._id}
            id={i._id}
            tourney={i.tourney}
            name={i.name}
            beginDate={i.beginDate}
            endDate={i.endDate}
            teamsCount={i.teams.length}
            status={i.status}
          />
        )}
        <Pagination
          offset={offset}
          limit={limit}
          count={count}
          reload={offset => this.setState({ offset })}
        />
        </div>
      </section>
    );
  }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NotesItem from './NotesItem.jsx';
import axios from 'axios';
import '../../scss/notessection.scss';

export default class NotesSection extends Component {
  state = {
    notes: null,
  }

  componentDidMount = async () => {
    const { data: announces } = await axios.get('/crud/announce');
    this.setState({ notes: announces });
  }

  render() {
    const { notes } = this.state;
    if (!notes) return <div>Loading...</div>;
    return (
      <section className="notes">
        <Link to="/notes"><h2 className="notes__header">Объявления</h2></Link>
        {notes.map(note => (
          <div key={note._id} className="notes__item">
            <NotesItem content={note.content} />
            <span className="notes__date">{note.date ? new Date(note.date).toLocaleString('ru') : ''}</span>
          </div>
        ))}
      </section>
    )
  }
}

import React from "react";
import ChessCell from "./ChessCell.jsx";
import "../../scss/chess.scss";
import { TeamName } from "../TeamName.jsx";

const ChessTable = ({ league, teams, withGroups }) => {
  return (
    <table className="chess">
      {withGroups && <h3>Группа {teams[0].group}</h3>}
      <thead>
        <tr>
          <th>№</th>
          <th>Команда</th>
          {teams.map((team, index) => (
            <th key={index}>{index + 1}</th>
          ))}
          <th>И</th>
          <th>В</th>
          <th>Н</th>
          <th>П</th>
          <th>Г</th>
          <th>РГ</th>
          <th>О</th>
        </tr>
      </thead>
      <tbody>
        {teams.sort(sortTeams(league)).map((team, index) => (
          <tr key={team._id}>
            <td>{index + 1}</td>
            <td className="chess__team-name">
              <TeamName team={team} />
            </td>
            {teams.map((otherTeam) => (
              <ChessCell
                key={`${team._id}${otherTeam._id}`}
                league={league}
                teamOne={team._id}
                teamTwo={otherTeam._id}
              />
            ))}
            <td>{team.wins + team.losses + team.draws}</td>
            <td>{team.wins}</td>
            <td>{team.draws}</td>
            <td>{team.losses}</td>
            <td>{`${team.goals}-${team.goalsReceived}`}</td>
            <td>{team.goals - team.goalsReceived}</td>
            <td>{team.groupScore}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const sortTeams = (league) => (teamA, teamB) => {
  if (teamA.groupScore !== teamB.groupScore)
    return teamB.groupScore - teamA.groupScore;

  if (teamA.wins !== teamB.wins) return teamB.wins - teamA.wins;

  //   const matches = league.matches.filter(m => {
  //     return m.played && ((m.teamOne === teamA._id && m.teamTwo === teamB._id)
  //       || (m.teamOne === teamB._id && m.teamTwo === teamA._id));
  //   });
  //   const { winsDif, goalsA, goalsB } = getStats(matches, teamA, teamB);
  //   if (winsDif !== 0) return -winsDif;

  //   if (goalsA !== goalsB) return goalsB - goalsA;

  if (teamA.goals - teamA.goalsReceived !== teamB.goals - teamB.goalsReceived) {
    return (
      teamB.goals - teamB.goalsReceived - (teamA.goals - teamA.goalsReceived)
    );
  }
  if (teamA.goals !== teamB.goals) return teamB.goals - teamA.goals;
  return 0.5 - Math.random();
};

// const getStats = (matches, teamA, teamB) => {
// 	return matches.reduce(({ winsDif, goalsA, goalsB }, match) => {
// 		let wd = winsDif;
// 		let goalsArr = match.score.split(':');
// 		if (teamA._id === match.teamOne) {
// 			if (match.winner === 'teamOne') wd += 1;
// 			if (match.winner === 'teamTwo') wd -= 1;
// 		} else {
// 			if (match.winner === 'teamOne') wd -= 1;
// 			if (match.winner === 'teamTwo') wd += 1;
// 		goalsArr.reverse();
// 		}
// 		return { winsDif: wd, goalsA: goalsA + goalsArr[0], goalsB: goalsB + goalsArr[1] };
// 	}, { winsDif: 0, goalsA: 0, goalsB: 0 });
// }

export default ChessTable;

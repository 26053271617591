import axios from "axios";
import React, { Component } from "react";
import "../../scss/cups/cups-page.scss";
import "../../scss/cups/players.scss";
import Announces from "../Cups/Announces.jsx";
import ChessSection from "../Cups/ChessSection.jsx";
import DetailCupItem from "../Cups/DetailCupItem";
import MatchesSchedule from "../Cups/MatchesSchedule";
import LinksBar from "../LinksBar.jsx";
import News from "../Cups/News";
import PlayersList from "../Cups/PlayersList.jsx";
import TableSection from "../Cups/TableSection.jsx";
import TeamsSection from "../Cups/TeamsSection.jsx";
import TourneyDetail from "./TourneyDetail";
import { Link } from "react-router-dom";
import TablesSection from "./TablesSection";
import InformationSection from "./InformationSection";
import NewsSection from "./NewsSection";

class TourneyPage extends Component {
  state = {
    league: null,
    players: null,
    matches: null,
    news: [],
    announces: [],
    activeTab: 0,
    tourney: null,
    leagues: null,
    teams: null,
  };

  componentDidMount = async () => {
    let lid = this.props.match.params.id;
    console.log("ID:", lid);
    const {
      data: [tourney],
    } = await axios.get(`/crud/tourney?_id=${lid}&_populate=region`);
    const { data: leagues } = await axios.get(
      `/crud/league?tourney=${lid}&_populate=team_request`
    );
    const { data: news } = await axios.get(
      `/crud/news?tourney=${lid}&_sort=-createdAt`
    );
    const { data: announces } = await axios.get(
      `/crud/announce?tourney=${lid}&_sort=-createdAt`
    );

    // Change to iterator
    const leaguesId = leagues.map((it) => it._id);

    const { data: matches } =
      leaguesId.length > 0
        ? await axios.get(
            `/crud/match?league=,${leaguesId.join(
              ","
            )}&_limit=1000&_populate=teamOne+teamTwo+place+league`
          )
        : { data: [] };

    const teamsId = leagues
      .map((it) => it.team_request)
      .flat()
      .filter((it) => it.status == "accepted")
      .map((it) => it.team);

    const { data: teams } =
      teamsId.length > 0
        ? await axios.get(`/crud/team?_id=,${teamsId.join(",")}`)
        : { data: [] };

    const { data: teamStats } =
      leaguesId.length > 0 && teamsId > 0
        ? await axios.get(
            `/stats/team?on_=,${leaguesId.join(",")}&team=,${teamsId.join(",")}`
          )
        : { data: [] };
    const es = () => ({
      wins: 0,
      losses: 0,
      draws: 0,

      goals: 0,
      goalsReceived: 0,
    });

    // from [team,team] where team:{legue:id,stats:undefined} to team:{league:league.name,stats:stats}
    teamStats.forEach((it) => (teams.find((t) => t._id == it.team).stats = it));
    teams.filter((it) => !it.stats).forEach((it) => (it.stats = es()));
    teams.forEach(
      (it) => (it.league = leagues.find((l) => l._id == it.league).name)
    );

    //define team has red

    this.setState({ tourney, leagues, matches, teams, news, announces });
  };

  changeTab = (e) => {
    this.setState({ activeTab: +e.target.dataset.index });
  };

  render() {
    const {
      tourney,
      leagues,
      players,
      activeTab,
      matches,
      news,
      announces,
      teams,
    } = this.state;
    // if (!league) return <div>Loading</div>;
    const tabs = [
      {
        title: "Расписание",
        component: <MatchesSchedule matches={matches} hideGroups />,
      },
      {
        title: "Таблицы",
        component: <TablesSection teams={teams} />,
      },
      {
        title: "Новости",
        component: tourney ? (
          <NewsSection news={news} announces={announces} />
        ) : null,
      },
      {
        title: "Регламент",
        component: tourney ? (
          <InformationSection {...tourney} teamCount={teams.length} />
        ) : null,
      },
      ...(leagues || []).map((it) => ({
        title: <Link to={`/cups/${it._id}`}>{it.name}</Link>,
      })),
      // // {
      // // 	title: 'Матчи',
      // // 	component: <MatchesPage league={league} />
      // // },
      // {
      //     title: 'Команды',
      //     component: <TeamsSection teams={league.teams} leagueId={league._id} />
      // },
      // {
      //     title: 'Турнирная таблица',
      //     component: <TableSection league={league} />
      // },
      // {
      //     title: 'Шахматка',
      //     component: <ChessSection league={league} />
      // },
      // {
      //     title: 'Статистика игроков',
      //     component: <PlayersList league={league} players={players} />
      // },
      // {
      //     title: 'Новости',
      //     component: <News news={news} />
      // },
      // {
      //     title: 'Объявления',
      //     component: <Announces announces={announces} />
      // },
      // {
      //     title: 'Документы'
      // },
      // {
      //     title: <a href={`https://football.bwadm.ru/tourney/${league.tourney._id}/league/${league._id}`} style={{ color: "#222" }}>Редактировать</a>
      // }
    ];

    return (
      <section>
        {tourney ? (
          <LinksBar
            links={[
              {
                to: "/",
                name: "Главная",
              },
              {
                to: "/tourneys",
                name: "Турниры",
              },
              {
                to: `/tourney/${this.props.match.params.id}`,
                name: `${tourney.name}`,
              },
            ]}
          />
        ) : null}
        {tourney ? (
          <TourneyDetail {...tourney} leagueCount={leagues.length} />
        ) : null}
        {tabs ? (
          <ul className="cup-page__tab-list">
            {tabs.map((i, index) => (
              <li
                key={index}
                className={`cup-page__tab ${
                  index === activeTab ? "cup-page__tab--active" : ""
                }`}
                onClick={this.changeTab}
                data-index={index}
              >
                {i.title}
              </li>
            ))}
          </ul>
        ) : null}
        {activeTab !== null && tabs[activeTab] && tabs[activeTab].component}
      </section>
    );
  }
}

export default TourneyPage;

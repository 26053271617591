import React from 'react';
import { Link } from 'react-router-dom';
import { getStageName } from '../../constants';

const opts = { day:'2-digit', month:'2-digit', year:'numeric' };

const CupItem = ({id, tourney, name, beginDate, endDate, teamsCount, status,region,ageMin,ageMax,yellowCards,transferBegin,transferEnd}) => (
  <>
  <Link className='cups__item' to={`/cups/${id}`}>
    <h3>{`${name}.`}</h3>
  </Link>
  </>
)



export default CupItem;

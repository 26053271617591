import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import axios from 'axios';
import NavBar from './components/NavBar.jsx';
import Main from './components/Main.jsx';
import Cups from './components/Cups/Cups.jsx';
import CupsPage from './components/Cups/CupsPage.jsx';
import AdsBar from './components/AdsBar.jsx';
import News from './components/News.jsx';
import SingleNew from './components/News/SingleNew.jsx';
import NewsPageRender from './components/News/NewsPageRender.jsx';
import MatchPage from './components/Match/MatchPage.jsx';
import NotesList from './components/Notes/NotesList.jsx';
import TeamsPage from './components/Teams/TeamsPage.jsx';
import Docs from './components/Docs/Docs.jsx';
import './scss/App.scss';
import { apiLink, newsLimit } from './constants';
import TourneysPage from './components/Tourney/TourneysPage.jsx';
import TourneyPage from './components/Tourney/TourneyPage.jsx';

class App extends Component {
  constructor() {
    super();
    if (!axios.defaults.baseURL) axios.defaults.baseURL = `${apiLink}/api`;
    console.log(axios.defaults.baseURL);
  }
  render() {
    return (
      <div className="App">
        <NavBar/>
        <div className='main'>
          <AdsBar/>
          {/* router */}
          <Switch>
            <Route exact path='/' component={Main} />
            <Route path="/pastcups" render={() => <Cups past />} />
            <Route path='/cups/:id' component={CupsPage} />
            <Route exact path='/cups' component={Cups} />
            <Route path='/tourney/:id' component={TourneyPage} />
            <Route exact path='/tourneys' component={TourneysPage}/>
            <Route path='/news/:id' component={SingleNew} />
            <Route path='/docs' component={Docs} />
            <Route exact path='/news' render={() => (
              <News limit={newsLimit}>{(news, offset, limit, count, reload) => (
                <NewsPageRender
                  list={news}
                  offset={offset}
                  limit={limit}
                  count={count}
                  reload={reload}
                />
              )}</News>
            )} />
            <Route exact path="/notes" component={NotesList} />
            <Route path="/match/:id" component={MatchPage} />
            <Route path="/team/:leagueId/:id" component={TeamsPage} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;

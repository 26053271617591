import React from 'react';
import '../../scss/cups/match-table.scss';
import TableSectionItem from './TableSectionItem.jsx';
// import withTableControls from './withTableControl.jsx';
import TableControls from './TableControls.jsx';


const TableSection = ({ teams }) => {
	const reformedData = teams.map(i => ({ ...i, ...i.stats }));
	let groups = [];
	reformedData.forEach(i => {
		if (!groups.some(j => j.name === i.group)) {
			groups.push({ name: i.group, teams: [i] });
		} else {
			groups[groups.findIndex(j => j.name === i.group)].teams.push(i);
	}
	});
	return (
		<section>
			{groups.map(group => (
				<TableControls key={group.name} tableData={group.teams}>
					{(changeSort, getClassName, list) => <TableSectionItem changeSort={changeSort} getClassName={getClassName} list={list} withGroups={groups.length > 1} />}
				</TableControls>
			))}
		</section>
	);
}

export default TableSection;

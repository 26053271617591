import React, { Component } from 'react';

export default class NotesItem extends Component {
  state = {
    full: false,
  }

  render() {
    const content = this.state.full ? this.props.content : this.props.content.slice(0,50);
    return (
      <span onClick={() => this.setState(({ full }) => ({ full: !full }))}>{content}</span>
    )
  }
}

import React from 'react';
import TableSection from "../Cups/TableSection";

function groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

function TablesSection({ teams }) {
    const gteams = groupBy(teams, 'league');
    console.log("GT:", gteams);

    return <>
        {
            Object.keys(gteams).map(key => [key, gteams[key]]).map(([key, teams]) => <>
                <span style={{margin:"8px",display:"block"}}>{key}</span>
                <TableSection teams={teams} />
            </>
            )
        }
    </>
}

export default TablesSection;
export const apiLink = `https://api.mf03.ru`;
export const notesLimit = 15;
export const leagueLimit = 10;
export const newsLimit = 5;

export const groupByDate = (matches, sortAsc = false) => {
  const slicedMatches = matches
    .sort((a,b) => a.date > b.date ? sortAsc - 0.5 : sortAsc + 0.5)
    .filter(match => match.date)
    .map(match => ({ ...match, groupDate: match.date.split('T')[0]}));
  return groupBy(slicedMatches, 'groupDate');
}

export const groupBy = (arr, prop) => {
  if (!arr) throw new Error('No array in arguments');
  return arr.reduce((groups, item) => {
    const idx = groups.findIndex(group => group.name === item[prop]);
    if (idx !== -1) {
      groups[idx].items.push(item);
    } else {
      groups.push({
        name: item[prop],
        items: [item],
      });
    }
    return groups;
  }, []);
}

export const getEventName = (event) => {
  const obj = {
    redCard: 'Красная карточка',
    yellowCard: 'Желтая карточка',
    goal: 'Гол',
    autoGoal: 'Автогол',
    penalty: 'Пенальти',
    foul: 'Фол',
  };
  return obj.hasOwnProperty(event) ? obj[event] : 'Другое событие';
}

export const getStageName = (stage) => {
  const obj = {
    Pending: 'Не начато',
    Groups: 'Групповая',
    Playoff: 'Плейофф',
    Finished: 'Закончено'
  };
  return obj.hasOwnProperty(stage) ? obj[stage] : 'Другая стадия';
}

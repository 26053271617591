import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { groupByDate } from '../constants';
import axios from 'axios';

export default class UpcomingMatches extends Component {
  state = {
    matches: null,
  }

  componentDidMount = async () => {
    // let url = new URL();
    const { data: matches } = await axios.get(`/crud/match?date=>=${new Date()}`);
    this.setState({ matches });
  }

  render() {
    const { matches } = this.state;
    if (!matches) return <div>Loading</div>;
    const data = groupByDate(matches, true);
    return (
      <section className='matches'>
        <div className='matches__heading'>Расписание матчей</div>
        <table className='matches__table'>
          <tbody>
            {data.length === 0 && <tr><td colSpan={3}>На ближайшую неделю матчей нет</td></tr>}
            {data.map(i => <MatchItem key={i.name} date={i.name} matches={i.items}/>)}
          </tbody>
        </table>
      </section>
    )
  }
}

const MatchItem = ({ date, matches }) => (
  <React.Fragment>
    <tr>
      <td className='matches__date' colSpan='3'>
        {new Date(date).toLocaleString('ru',{year:'numeric',month:'2-digit',day:'2-digit'})}
      </td>
    </tr>
    {matches.map(match => (
        <tr key={match._id} className='matches__row'>
          <td className='matches__item'>
            <span className='matches__item-secondary'>
              {new Date(match.date).toLocaleString('ru',{hour:'2-digit',minute:'2-digit'})}
            </span>
            <span>
              <Link to={`/team/${match.leagueId}/${match.teamOne._id}`} className='matches__link'>
                {match.teamOne.name}
              </Link>
            </span>
          </td>
          <td className='matches__item--small'>
            <Link to={`/match/${match._id}`} className='matches__link'>
              {match.score || '—'}
            </Link>
          </td>
          <td className='matches__item'>
            <span>
              <Link to={`/team/${match.leagueId}/${match.teamTwo._id}`} className='matches__link'>
                {match.teamTwo.name}
              </Link>
            </span>
            {match.place && <span className='matches__item-secondary'>{match.place.split(':')[0]}</span>}
          </td>
        </tr>
      )
    )}
  </React.Fragment>
);
